
import './App.css';
import axios from 'axios';

import React, { useState } from 'react'
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, CartesianGrid, Tooltip, Legend } from 'recharts'

const Chart = () => {
    const [data, setData] = useState([])

    function getData() {
        console.log("Get data")

        const options = {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json;charset=UTF-8",
            },
        };
    
        axios.get('https://photon-2.nick-particle.workers.dev').then(resp => {

            var out = []

            for (var i = 0; i < resp.data.data.length; i++) {
                var item = resp.data.data[i];
                out.push({
                    name: 'Page A',
                    uv: item,
                    pv: item,
                })
            }

            console.log('data', resp.data.data)

            console.log('out', out)

            setData(out)
        });

        // fetch('https://photon-2.nick-particle.workers.dev', {method: 'GET'})
        //     .then(res => res.json())
        //     .then(jsonData => {
        //         // parse json data and set state
        //         const parsedData = jsonData.map(str => JSON.parse(str))
        //         setData(parsedData)
        //     })
        //     .catch(err => console.log(err))
    }
  
    setTimeout(() => {
        console.log("Get data");
        getData();
    }, 1000);

    return (
        <LineChart width={300} height={100} data={data}>
          <Line type="monotone" dataKey="pv" stroke="#8884d8" strokeWidth={2} />
        </LineChart>
    );
}

function App() {
  return (
    <div className="App">
      <Chart />
    </div>
  );
}

export default App;
